<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row>
          <b-col>
            <v-select
              id="id_com_realty_project"
              v-model="id_com_realty_project"
              :options="projects"
              label="title"
              :reduce="project => project.id"
              placeholder="Proje Seçiniz"
            />
          </b-col>
        </b-row>
        <b-table
          v-if="id_com_realty_project"
          class="mt-1"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(interview_number)="data">
            <b-button
              variant="flat-primary"
              size="sm"
              :to="'/interviews/view/' + data.item.interview_number"
            >
              {{ data.item.interview_number }}
            </b-button>
          </template>
          <template #cell(realty_type)="data">
            <div>{{ data.item.realty_type }}</div>
            <div class="text-muted font-small-2">
              <div>Blok: {{ data.item.realty_block }}</div>
              <div>Kat: {{ data.item.floor }}</div>
              <div>Kapı No: {{ data.item.number }}</div>
            </div>
          </template>
          <template #cell(listprice)="data">
            {{ data.item.listprice | toCurrency }} ₺
          </template>
          <template #cell(saleprice)="data">
            {{ data.item.saleprice | toCurrency }} ₺
          </template>
          <template #cell(status)="data">
            {{ data.item.status }}
            <div
              v-if="data.item.sdate"
              class="text-success font-small-2"
            >
              Satış: {{ moment(data.item.sdate).format('ll') }}
            </div>
            <div
              v-if="data.item.odate"
              class="text-warning font-small-2"
            >
              Opsiyon: {{ moment(data.item.odate).format('ll') }}
            </div>
            <div
              v-if="data.item.cdate"
              class="text-danger font-small-2"
            >
              İptal: {{ moment(data.item.cdate).format('ll') }}
            </div>
          </template>
          <template #cell(block)="data">
            <div>
              {{ data.item.block }}
            </div>
            <div class="font-small-2 text-warning">
              Kat: {{ data.item.floor }}
            </div>
            <div class="font-small-2 text-muted">
              No: {{ data.item.number }}
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/realty/sales/edit/' + data.item.id"
              variant="outline-primary"
              size="sm"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer v-if="id_com_realty_project">
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    vSelect,
  },
  data() {
    return {
      id_com_realty_project: null,
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'interview_number',
          label: 'Görüşme No',
        },
        {
          key: 'customer',
          label: 'Müşteri Adı',
        },
        {
          key: 'realty_type',
          label: 'Gayrimenkul Tipi',
        },
        {
          key: 'listprice',
          label: 'Liste Fiyatı',
        },
        {
          key: 'saleprice',
          label: 'Satış Fiyatı',
        },
        {
          key: 'saleprice',
          label: 'Satış Fiyatı',
        },
        {
          key: 'status',
          label: 'Durum',
        },
        {
          key: 'username',
          label: 'Danışman',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          'com_realty_sale.id AS id',
          'com_realty_sale.interview_number AS interview_number',
          'com_customer.name AS customer',
          'com_realty_type.title AS realty_type',
          'com_realty_block.title AS realty_block',
          'com_realty_number.floor AS floor',
          'com_realty_number.number AS number',
          'com_realty_sale.listprice AS listprice',
          'com_realty_sale.saleprice AS saleprice',
          'com_realty_sale_status.title AS status',
          'com_user.name AS username',
          'com_realty_sale.sdate AS sdate',
          'com_realty_sale.odate AS odate',
          'com_realty_sale.cdate AS cdate',
        ],
        order_by: ['com_realty_sale.id', 'ASC'],
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    projects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
    dataList() {
      return this.$store.getters['realtySales/dataList']
    },
    dataCount() {
      return this.$store.getters['realtySales/dataCounts']
    },
    saveStatus() {
      return this.$store.getters['realtySales/dataSaveStatus']
    },
  },
  watch: {
    id_com_realty_project(val) {
      this.dataQuery.where = {
        'com_realty_sale.id_com_realty_project': val,
      }
      this.getDataList()
    },
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects() {
      this.$store.dispatch('realtyProjects/realty_sale_projectsList', {
        select: [
          'com_realty_project.id AS id',
          'com_realty_project.title AS title',
        ],
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('realtySales/getDataList', this.dataQuery)
    },
  },
}
</script>
